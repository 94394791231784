import React from 'react';
import CacheBuster from 'react-cache-buster';
import { Routes } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import renderRoutes from './Route';
import LoadingScreen from './Route/LoadingScreen';
import packageInfo from '../package.json';
import theme from './theme';

function App() {
  return (
    <CacheBuster
      currentVersion={packageInfo.version}
      isEnabled // If false, the library is disabled.
      isVerboseMode={false} // If true, the library writes verbose logs to console.
      loadingComponent={<LoadingScreen />} // If not pass, nothing appears at the time of new version check.
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>{renderRoutes()}</Routes>
      </ThemeProvider>
    </CacheBuster>
  );
}

export default App;

import { Suspense } from 'react';
import { Route } from 'react-router-dom';
import routes, { IRoutes } from './routes';
import LoadingScreen from './LoadingScreen';

export default function renderRoutes() {
  const renderedRoutes = routes.map((route: IRoutes) => {
    const Component = route.component;
    const { path } = route;

    return (
      <Route
        key={`route-${route.id}`}
        path={path}
        element={
          <Suspense fallback={<LoadingScreen />}>
            <Component />
          </Suspense>
        }
      />
    );
  });

  return renderedRoutes;
}

import { lazy, LazyExoticComponent } from 'react';

export interface IRoutes {
  id: string;
  path: string;
  name: string;
  component: LazyExoticComponent<() => JSX.Element>;
}

const routes: IRoutes[] = [
  {
    id: 'home',
    path: '/',
    name: 'Track an Order',
    component: lazy(() => import('../pages/Home')),
  },
  {
    id: 'search',
    path: '/search',
    name: 'Search an Order',
    component: lazy(() => import('../pages/Search')),
  },
];

export default routes;
